export const links = (email) => {
  console.log(email);
  return [
    {
      to: "/",
      icon: "fas fa-qrcode",
      labelArabic: "الرئيسية",
      labelEnglish: "Home",
    },
    // {
    //   to: "/notifications",
    //   icon: "fa-regular fa-bell",
    //   labelArabic: "الإشعارات",
    //   labelEnglish: "Home",
    // },
    {
      to: "/reviews",
      icon: "fa-regular fa-bell",
      labelArabic: "الأراء",
      labelEnglish: "Reviews",
    },
    {
      to: "/categories",
      icon: "fas fa-link",
      labelArabic: "الفئات",
      labelEnglish: "Categories",
    },
    {
      to: "/products",
      icon: "fas fa-stream",
      labelArabic: "المنتجات",
      labelEnglish: "Products",
    },
    {
      to: "/Store",
      icon: "fas fa-stream",
      labelArabic: "المخزن",
      labelEnglish: "Store",
    }, {
      to: "/subcategoryrecipes",
      icon: "fas fa-stream",
      labelArabic: "فئه  للوصفات",
      labelEnglish: "Recipes Categories",
    },
    {
      to: "/recipes",
      icon: "fas fa-stream",
      labelArabic: " كل الوصفات",
      labelEnglish: "Recipes",
    },
    {
      to: "/Orders",
      icon: "fas fa-calendar-week",
      labelArabic: "الطلبات",
      labelEnglish: "Orders",
    },
    email == "admin@greentrees.com"
      ? {
          to: "/management",
          icon: "fa-solid fa-user-tie",
          labelArabic: "الإدارة",
          labelEnglish: "Management",
        }
      : {},
    {
      to: "/Sections",
      icon: "fa-solid fa-user-tie",
      labelArabic: "السكاشن",
      labelEnglish: "Sections",
    },
    {
      to: "/about",
      icon: "far fa-question-circle",
      labelArabic: "عن الموقع",
      labelEnglish: "About",
    },
    // {
    //   to: "/ideas",
    //   icon: "fa-regular fa-lightbulb",
    //   labelArabic: "الأفكار",
    //   labelEnglish: "Ideas",
    // },
    {
      to: "/banners",
      icon: "fas fa-images",
      labelArabic: "البانرات",
      labelEnglish: "Banners",
    },
    // {
    //   to: "/brands",
    //   icon: "fa-solid fa-tag",
    //   labelArabic: "الماركات",
    //   labelEnglish: "Ideas",
    // },
    {
      to: "/rejions",
      icon: "fas fa-truck",
      labelArabic: "المنطقة",
      labelEnglish: "Regions",
    },
    {
      to: "/footerdetails",
      icon: "fas fa-sort-down",
      labelArabic: "بيانات الفوتر",
      labelEnglish: "Footer",
    },
    {
      to: "/coupons",
      icon: "fas fa-medal",
      labelArabic: "الكوبونات",
      labelEnglish: "Copouns",
    },
    {
      to: "/commonquestions",
      icon: "fa-regular fa-circle-question",
      labelArabic: "الأسئلة الشائعة",
      labelEnglish: "FAQ",
    },
    {
      to: "/contacts",
      icon: "far fa-envelope",
      labelArabic: "معلومات التواصل",
      labelEnglish: "Contact",
    },
  ];
};

export const Archived = [
  {
    to: "/ArchivedCategories",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الفئات",
    labelEnglish: "Archived Categories",
  },
  {
    to: "/ArchivedProducts",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف المنتجات",
    labelEnglish: "Archived Products",
  },
  // {
  //   to: "/BrandsArchive",
  //   icon: "fas fa-qrcode",
  //   labelArabic: "أرشيف الماركات",
  //   labelEnglish: "Brands Archive",
  // },
  {
    to: "/BannersArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف البانرات",
    labelEnglish: "Banners Archive",
  },
  {
    to: "/SubCategoryArchived",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الفئات الفرعية",
    labelEnglish: "Sub Categories Archive",
  },
  {
    to: "/SectionsArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف السكاشن",
    labelEnglish: "Sections Archive",
  },
  // {
  //   to: "/ArchivedIdeas",
  //   icon: "fas fa-qrcode",
  //   labelArabic: "أرشيف الأفكار",
  //   labelEnglish: "Archived Ideas",
  // },
  {
    to: "/RejionsArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف المناطق",
    labelEnglish: "Archived Regions",
  },
  {
    to: "/DistrictsArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الأحياء",
    labelEnglish: "Archived Disricts",
  },
  {
    to: "/RecipesArchive",
    icon: "fas fa-qrcode",
    labelArabic: "أرشيف الوصفات",
    labelEnglish: "Archived Recipes",
  },
];
