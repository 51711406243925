import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./style.css";
import { Loader } from "rsuite";
import axios from "axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import SelectWithSearch from "../../components/selectWithSearch/SelectWithSearch";
import { addition, exitModal, plus } from "../../assets/svgIcons";
import Select from "react-select";
import imageAvatar from "../../assets/image_placeholder.png";

import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";

const EditProductPage = () => {
  const {language} = UseGeneral();
  const location = useLocation();
  const { id } = useParams();
  const [productData, setProdcutData] = useState({});
  const [imagesLinks, setImagesLinks] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedSubCategoriesArr, setSelectedSubCategoriesArr] = useState([]);

  const subCatId = location?.state?.subCat;
  const subCatName = location?.state?.subCatName;
  const [addLoading, setAddLoading] = useState(false);

  const [defualtVals, setDefaultVasls] = useState(null);

  const [newProd, setNewProd] = useState({
    title_ar: productData?.title_ar,
    title_en : productData?.title_en,
    description_ar: productData?.description_ar ,
    description_en:  productData?.description_en,
    price: "",
    discount: "",
    hasOptions: 0,
    subCatId: '',
    count: '',
    pricePerUnit: '',
    quantity: productData?.quantity,
    barcode: productData?.Barcode,
    // images:image1**green**image2**green**image3,
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [allProducts, setAllProducts] = useState(null);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setCategories(res?.data?.result);
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [isKilo, setIsKilo] = useState(false);

  const [weights, setWeights] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [hasOptions, setHasOptions] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const getProductById = async () => {
    setDataLoading(true);
    await axios
      .post(`${BASE_URL}products/get_product_by_id/${id}`, { id: 1 })
      .then((res) => {
        if (res.data.message == "Success") {
          setProdcutData(res?.data?.result);
          setImagesLinks(res?.data?.result?.images);
          setHasOptions(res?.data?.result?.has_options == "1" ? true : false);
          let subcategoryproduct = [...res?.data?.result?.subcategoryproduct];
          console.log(subcategoryproduct);
          let pushedArr = [];
          let pushedTxt = '';
          for (let i = 0; i < subcategoryproduct.length; i++) {
            let obj = {
              label: language == "ar" ?  subcategoryproduct[i].subcategory.title_ar : subcategoryproduct[i].subcategory.title_en,
              value: subcategoryproduct[i].subcategory_id,
            };
            if (i == 0) {
              pushedTxt += subcategoryproduct[i].subcategory_id;
            } else {
              pushedTxt += '**green**' + subcategoryproduct[i].subcategory_id;
            }
            // setSelectedSubCategoriesArr([...selectedSubCategoriesArr,subcategoryproduct[i].subcategory_id])
            pushedArr.push(obj);
          }
          setSelectedSubCategories(pushedTxt);
          setSelectedSubCategoriesArr(pushedArr);

          console.log(pushedArr);
          const ingrediants =
            res?.data?.result?.ingrediants?.map((item) => {
              return {
                id: item.id,
                ar_value: language == "ar" ?  item.text_ar : item.text_en,
                en_value: language == "ar" ?  item.text_ar : item.text_en,
                englishLang: false,
              };
            }) || [];

          setArIngs(ingrediants);

          const stepsArr =
            res?.data?.result?.steps?.map((item) => {
              return {
                id: item.id,
                ar_value: language == "ar" ? item.step_ar : item.step_en,
                en_value: language == "ar" ? item.step_ar : item.step_en,
                englishLang: false,
              };
            }) || [];

          setSteps(stepsArr);

          const options =
            res?.data?.result?.options?.map((item, index) => {
              return {
                id: index + 1,
                product_id: item?.element_id,
                price: item?.price,
                count: item?.weight,
                title_ar: language == "ar" ? item?.product.title_ar : item?.product.title_en,
                title_en:  language == "ar" ? item?.product.title_ar : item?.product.title_en,
              };
            }) || [];

          setProducts(options);

          const policiesData = res?.data?.result?.returnpolicies?.map(
            (item, index) => {
              return {
                id: index + 1,
                value: item?.text,
              };
            }
          );

          setPolicies(policiesData);

          const relatedProds = res?.data?.result?.productrelateds?.map(
            (prod) => {
              return { value: +prod.product_id, label: language == "ar" ? prod.product.title_ar :  prod.product.title_en };
            }
          );

          const weightsData = res?.data?.result?.productweights?.map(
            (prod, index) => {
              return { id: index + 1, value: prod?.weight };
            }
          );
          setWeights(weightsData);

          setIsKilo(res.data.result.price_for == 'weight' ? true : false);

          setDefaultVasls([
            {
              value: 14,
              label: 'جديد',
            },
            {
              value: 15,
              label: 'جديد',
            },
          ]);

          const selects = allProducts?.map((prod) => {
            return { value: +prod.id, label: language == "ar" ? prod.title_ar : prod.title_en };
          });

          setNewProd({
            title_ar:  res?.data?.result?.title_ar ,
            title_en: res?.data?.result.title_en,
            description_ar:  res?.data?.result.description_ar,
            description_en: res?.data?.result.description_en,
            price: res?.data?.result?.price,
            discount: res?.data?.result?.discount,
            quantity: res?.data?.result?.quantity,
            barcode: res?.data?.result?.Barcode,
            
            // hasOptions: res?.data?.result?.has_options,
          });
        } else if (res.data.status == "error") {
          setProdcutData([]);
        } else {
          setProdcutData([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
    getAllProducts();
    getProductById();
  }, []);

  useEffect(() => {
    // setNewProd({
    //   title_ar: productData?.title_ar,
    //   title_en: productData.title_en,
    //   description_ar: productData.description_ar,
    //   description_en: productData.description_en,
    //   price: productData?.price,
    //   discount: productData?.discount,
    //   // hasOptions: productData?.has_options,
    // });
    // console.log(productData?.images)
  }, [productData]);

  // const getSubCategories = async (id) => {
  //   // /categories/category_subcategories/1?

  //   const token = localStorage.getItem("GreenTreesAdminToken");
  //   await axios
  //     .get(`${BASE_URL}categories/category_subcategories/${id}?token=${token}`)
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status == "success") {
  //         setSubCategories(res?.data?.result);
  //         console.log(res.data.result);
  //       } else if (res.data.status == "error") {
  //         setSubCategories([]);
  //       } else {
  //         setSubCategories([]);
  //       }
  //     })
  //     .catch((e) => console.log(e))
  //     .finally(() => {});
  // };

  useEffect(() => {
    // getSubCategories(data?.id);
    setNewProd({
      ...newProd,
      subCatId: '',
    });
  }, [data]);

  useEffect(() => {
    // getSubCategories(data?.id);
    setNewProd({
      ...newProd,
      subCatId: subCatData.id,
    });
  }, [subCatData]);

  const getAllProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language =="ar" ?  "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [lang, setLang] = useState({
    prod_name: true,
    prod_desc: true,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleDeleteImageLink = (index) => {
    const updatedImages = [...imagesLinks];
    updatedImages.splice(index, 1);
    setImagesLinks(updatedImages);
  };

  const handleEditProduct = async () => {
    const allImages = imagesLinks.join("**green**");

    const imgslinks = imagesLinks.map((item) => item.url);
    const result = [...imagesUrl, ...imgslinks].join('**green**');

    if (!newProd?.title_ar) {
      toast.error(language == "ar" ? "قم بإدخال إسم المنتج باللغة العربية" : "Please enter the product name in Arabic.");
      return;
    }
    if (!newProd?.title_en) {
      toast.error(language == "ar" ?"قم بإدخال إسم المنتج باللغة الإنجليزية" : "Please enter the product name in English.");
      return;
    }
    if (!newProd?.description_ar) {
      toast.error(language == "ar" ?  "قم بإدخال وصف المنتج باللغة العربية" : "Please enter the product description in Arabic.");
      return;
    }
    if (!newProd?.description_en) {
      toast.error(language == "ar" ? "قم بإدخال وصف المنتج باللغة الإنجليزية" : "Please enter the product description in English.");
      return;
    }

    if (!newProd?.price) {
      toast.error(language == "ar" ? "قم بإدخال سعر الوحدة " : "Please enter the unit price.");
      return;
    }

    if (+newProd.price < 0) {
      toast.error(language == "ar" ? " لا يمكن أن يكون السعر بقيمة سالبة " : "The price cannot be negative.");
      return;
    }

    if (newProd.discount && +newProd.discount > +newProd.price) {
      toast.error(language == "ar" ? " لا يمكن أن يكون  قيمة الخصم أكبر سعر الوحدة " : "The discount amount cannot be greater than the unit price.");
      return;
    }

    if (newProd.discount && +newProd.discount < 0) {
      toast.error(language == "ar" ? " لا يمكن أن يكون  الخصم بقيمة سالبة " : "The discount cannot be negative.");
      return;
    }

    // if (!subCatData) {
    //   toast.error("قم بإدخال فئة فرعية أولا ");
    //   return;
    // }

    if (!result.length >= 1) {
      toast.error(language == "ar" ? "قم بإضافة صور للمنتج"  : "Please add images of the product.");
      return;
    }

    // const images = imagesUrl.join("**green**");

    const ingrediants_en = hasOptions
      ? arIngs.map((ing) => ing.en_value).join("**green**")
      : "";
    const ingrediants_ar = hasOptions
      ? arIngs.map((ing) => ing.ar_value).join("**green**")
      : "";
    const productsData = hasOptions
      ? products
          .map((prod) => {
            return `${prod.product_id}*green*${prod.price}*green*${prod.count}`;
          })
          .join("**green**")
      : "";

    // const relatedProducts = selectedOptions?.join("**green**");
    const policiesData = policies.map((pol) => pol.value).join("**green**");
    const steps_ar = hasOptions
      ? steps.map((step) => step.ar_value).join("**green**")
      : "";
    const steps_en = hasOptions
      ? steps.map((step) => step.en_value).join("**green**")
      : "";

    const weightsData = weights.map((w) => w.value).join("**green**");

    const dataSet = {
      ...newProd,
      title_ar: language == "ar" ? newProd.title_ar : newProd.title_en,
      title_en: language == "ar" ? newProd.title_ar : newProd.title_en,
      description_ar: language == "ar" ?  newProd.description_ar : newProd.description_en,
      description_en: language == "ar" ? newProd.description_ar :  newProd.description_en,
      price: newProd.price,
      discount: newProd.discount,
      images: result,
      subcategories_ids: selectedSubCategories,
      subcategory_id: subCatData?.id || productData?.subcategory?.id,
      has_options: 0,
      id: id,
      policies: policiesData,
      category_id: data?.id || productData?.category_id,
      price_for: isKilo ? 'weight' : 'piece',
      subcategory_ids: selectedSubCategories,
      weights: isKilo ? weightsData : '', //weights
      // ingrediants_ar,
      // ingrediants_en,
      // steps_ar,
      // steps_en,
      // products: productsData,
      // related_products: relatedProducts,
    };
    const token = localStorage.getItem("GreenTreesAdminToken");

    setAddLoading(true);
    await axios
      .post(`${BASE_URL}products/update_product?token=${token}`, dataSet)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(language == "ar" ? "تم تعديل  المنتج  بنجاح" : "The product has been successfully updated.");
          getProductById();
          setImagesLinks([]);
          setImagesUrl([]);
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" :"An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setNewCat({
        //   title_ar: "",
        //   title_en: "",
        //   color: "",
        // });
        // setSelectedFile(null);
        setAddLoading(false);
      });
  };

  const getAllSubcategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .get(`${BASE_URL}subcategories/get_all_for_admin?token=${token}`)
      .then((res) => {
        console.log(res.data);
        if (Array.isArray(res.data.result)) {
          setSubCategories(res?.data?.result);
          console.log(res?.data?.result);
        }
        if (res.data.message == "success") {
          console.log(res?.data?.result, "res?.data?.result");
          // console.log(res.data.result);
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const handleUploadImages = async () => {
    setImagesLoading(true);
    if (selectedImages.length >= 1) {
      const arr = [];
      for (let i = 0; i < selectedImages.length; i++) {
        const formData = new FormData();
        formData.append("image", selectedImages[i]);
        await axios
          .post(`${BASE_URL}upload_image`, formData)
          .then((res) => {
            if (res.data && res?.status == 201) {
              arr.push(res?.data?.result?.image);
              // setImagesLinks([...imagesLinks, res?.data?.result?.image])
              // console.log([...imagesLinks, res?.data?.result?.image])
              toast.success(language == "ar" ? "تم رفع الصورة بنجاح" : "The image has been successfully added.");
            } else {
              toast.error(language == "ar" ?"حدث خطأ ما" : "An error occurred");
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {});
      }
      setImagesUrl([...arr]);
    } else {
    }
    setImagesLoading(false);
  };
  useEffect(() => {
    getAllSubcategories();
  }, []);

  useEffect(() => {
    console.log(lang.prod_name);
  } , [lang])

  const productNameLabel = language === "ar"
  ? "اسم المنتج باللغة"
  : "Product Name in Language";

const languageLabel = lang.prod_name
  ? (language === "ar" ? "الإنجليزية" : "English")
  : (language === "ar" ? "العربية" : "Arabic");


  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3 className="">{language == "ar" ? "تعديل منتج" : "Edit Product"}</h3>
        {subCatName ? <h3> - {language == "ar" ? "للفئة الفرعية " : "for subCategory"} ({subCatName})</h3> : ""}
      </div>

      {dataLoading ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditProduct();
          }}
        >
          <div className="fields row gy-4">
            <div className="field_input  col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">
                {productNameLabel} {languageLabel}
                  {/* {language == "ar" ? "اسم المنتج باللغة " : "Product Name in Language"} {lang.prod_name ? language == "ar" ? "الإنجليزية" :"English" : language == "ar"? "العربية":"Arabic"} */}
                </label>

                <div class="toggle-switch">
                  <input
                  checked ={lang.prod_name}
                  value={lang.prod_name}
                    class="toggle-input"
                    id="prod_name"
                    type="checkbox"
                    onChange={(e) => {
                      setLang({
                        ...lang,
                        prod_name: e.target.checked,
                      });
                    }}
                  />
                  <label class="toggle-label" for="prod_name"></label>
                </div>
              </div>
              <input
                type="text"
                // value={(language == "en" && lang.prod_name)  ? newProd?.title_en : newProd?.title_ar}
                value={(lang.prod_name  ? newProd?.title_en : newProd?.title_ar)}
                onChange={(e) => {
                  lang.prod_name 
                    ? setNewProd({
                        ...newProd,
                        title_en: e.target.value,
                      })
                    : setNewProd({
                        ...newProd,
                        title_ar: e.target.value,
                      });
                }}
              />
            </div>
            <div className="field_input col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">
                  {language == "ar" ? "وصف المنتج باللغة " : "Product Description in Language"} {lang.prod_desc ? language == "ar" ? "الإنجليزية" :"English" :  language == "ar"? "العربية" : "Arabic"}
                </label>

                <div class="toggle-switch">
                  <input
                  checked={lang.prod_desc}
                    value={lang.prod_desc}
                    class="toggle-input"
                    id="prod_desc"
                    type="checkbox"
                    onChange={(e) => {
                      setLang({
                        ...lang,
                        prod_desc: e.target.checked,
                      });
                    }}
                  />
                  <label class="toggle-label" htmlFor="prod_desc"></label>
                </div>
              </div>

              <input
                type="text"
                value={
                  lang.prod_desc
                    ? newProd.description_en
                    : newProd.description_ar
                }
                onChange={(e) => {
                  lang.prod_desc
                    ? setNewProd({
                        ...newProd,
                        description_en: e.target.value,
                      })
                    : setNewProd({
                        ...newProd,
                        description_ar: e.target.value,
                      });
                }}
              />
            </div>

            <div className="field_input col-md-6">
              <label htmlFor="">{language == "ar" ? "سعر الوحدة" : "Price"}</label>
              <input
                value={newProd.price}
                type="number"
                onChange={(e) => {
                  setNewProd({
                    ...newProd,
                    price: e.target.value,
                  });
                }}
              />
            </div>
            <div className="field_input col-md-6">
              <label htmlFor="">{language == "ar"?"قيمة الخصم" : "Discount"}</label>
              <input
                value={newProd.discount}
                type="number"
                onChange={(e) => {
                  setNewProd({
                    ...newProd,
                    discount: e.target.value,
                  });
                }}
              />
            </div>

            {!subCatId ? (
              <>
                {/* <div className="field_input col-md-6">
                  <label htmlFor="">الفئة</label>

                  <SelectWithSearch
                    options={categories}
                    getClientData={setData}
                    defaultValue={productData?.subcategory?.category_id}
                  />
                </div> */}
                <div className="field_input col-md-6">
                  <label htmlFor="">{language == "ar" ? "الفئة الفرعية" : "Subcategory"}</label>
                  {/* <SelectWithSearch
                    options={subCategories}
                    getClientData={setSubCatData}
                    defaultValue={productData?.subcategory_id}
                  /> */}
                  <Select
                    placeholder={language == "ar" ? "الفئات الفرعيه" :"Subcategories"}
                    isMulti
                    value={selectedSubCategoriesArr}
                    onChange={(e) => {
                      console.log(e);
                      let pushedSubCatTxt = '';
                      let pushedArr = [];
                      for (let i = 0; i < e.length; i++) {
                        // console.log(e[i]);
                        // return
                        let obj = {
                          label: e[i].label,
                          value: e[i].value,
                        };
                        console.log(obj);
                        pushedArr.push(obj);
                        setSelectedSubCategoriesArr(pushedArr);
                        // console.log(pushedArr)
                        if (i == 0) {
                          pushedSubCatTxt += e[i].value;
                        } else {
                          pushedSubCatTxt += "**green**" + e[i].value;
                        }
                      }
                      setSelectedSubCategories(pushedSubCatTxt);
                    }}
                    // value={selectedSubCategories}
                    isDisabled={subCategories == null}
                    name="subcategories"
                    options={
                      subCategories &&
                      subCategories != null &&
                      Array.isArray(subCategories) &&
                      subCategories.length > 0 &&
                      subCategories != null &&
                      subCategories.map((item, index) => {
                        return { label: language == "ar" ?  item.title_ar :  item.title_en, value: item.id };
                      })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {console.log(
                    selectedSubCategories,
                    "setSelectedSubCategories"
                  )}
                </div>
              </>
            ) : null}
          </div>

          {/* <div className="field_input col-md-6 my-4">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">المخزن</label>

              <div class="">
                <input
                  class=""
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={newProd?.quantity}
                  // defaultValue={productData?.quantity}
                  onChange={(e) =>
                    setNewProd({
                      ...newProd,
                      quantity: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div> */}

          <div className="field_input col-md-6 my-4">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">{language == "ar" ? "الباركود" : "Barcode"}</label>

              <div class="">
                <input
                  class=""
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={newProd?.barcode}
                  onChange={(e) =>
                    setNewProd({
                      ...newProd,
                      barcode: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="field_input col-md-6 my-4">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">{language == "ar" ? "الكمية" : "Quantity"} {isKilo ? language == "ar" ? "بالكيلو" :"Kilo" : language =="ar" ? "بالقطعة" : "Piece"  }</label>

              <div class="toggle-switch">
                <input
                  class="toggle-input"
                  id="iskilo"
                  type="checkbox"
                  checked={isKilo}
                  onChange={(e) => {
                    setIsKilo(e.target.checked);
                  }}
                />
                <label class="toggle-label" for="iskilo"></label>
              </div>
            </div>
          </div>


          {isKilo ? (
            <>
              <div className="d-flex align_items-center justify-content-between">
                <h4>{language == "ar" ? "الأوزان المتاحة" : "Available Weights"}</h4>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setWeights([
                      ...weights,
                      { value: "", id: weights.length + 1 },
                    ]);
                  }}
                >
                  {plus}
                </div>
              </div>

              {weights && weights.length >= 1
                ? weights.map((weight, index) => {
                    return (
                      <div className="ing_container mb-3">
                        <div
                          className="deleteIng"
                          onClick={() => {
                            setWeights((prev) =>
                              prev.filter((item) => item.id != weight.id)
                            );
                          }}
                        >
                          {exitModal}
                        </div>

                        <div className="fields row w-100 ">
                          <div className="field_input col-sm-12">
                            <div className="d-flex align-items-center gap-4">
                              <label htmlFor="">
                                {/* باللغة {ing.englishLang ? "الإنجليزية" : "العربية"} */}
                                ({index + 1}) :{language == "ar" ? "السعر" : "Price"}
                                <span className="fw-bolder text-primary mx-2">
                                  {+weight.value * +newProd.price}
                                </span>
                                جنيه
                              </label>
                            </div>

                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={weight.value}
                              onChange={(e) => {
                                setWeights((prev) =>
                                  prev.map((item) =>
                                    item.id == weight.id
                                      ? {
                                          ...item,
                                          value: parseFloat(e.target.value),
                                        }
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </>
          ) : null}

          <div className="images_container my-3">
            <h4>{language == "ar" ? "اختر صورة المنتج" : "Choose Product Image"}</h4>

            <div className="images">
              <label className="image" htmlFor="mul_images">
                <img
                  className="avatar"
                  src={
                    "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                  }
                  alt=""
                />
              </label>
              <input
                type="file"
                multiple
                name=""
                id="mul_images"
                className="d-none"
                onChange={handleImageChange}
              />

              {imagesLinks?.map((image, index) => {
                return (
                  <div className="image">
                    <img src={image.url} alt="" />
                    <div
                      className="delete_btn"
                      onClick={() => handleDeleteImageLink(index)}
                    >
                      {exitModal}
                    </div>
                  </div>
                );
              })}

              {selectedImages.length >= 1
                ? selectedImages.map((imageFile, index) => {
                    return (
                      <div className="image">
                        <img src={URL.createObjectURL(imageFile)} alt="" />
                        <div
                          className="delete_btn"
                          onClick={() => handleDeleteImage(index)}
                        >
                          {exitModal}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleUploadImages();
              }}
              className="popup_agree_btn"
            >
              {imagesLoading ? <Loader /> : language == "ar" ? "رفع الصور"  :"Upload Image"}
            </button>
          </div>

          <div className="d-flex align_items-center justify-content-between">
            <h4>{language == "ar" ? "سياسات المنتج" : "Product Policies"}</h4>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setPolicies([
                  ...policies,
                  { value: "", id: policies.length + 1 },
                ]);
              }}
            >
              {plus}
            </div>
          </div>

          {policies && policies.length >= 1
            ? policies.map((policy, index) => {
                return (
                  <div className="ing_container mb-3">
                    <div
                      className="deleteIng"
                      onClick={() => {
                        setPolicies((prev) =>
                          prev.filter((item) => item.id != policy.id)
                        );
                      }}
                    >
                      {exitModal}
                    </div>

                    <div className="fields row w-100 ">
                      <div className="field_input col-sm-12">
                        <div className="d-flex align-items-center gap-4">
                          <label htmlFor="">
                            {/* باللغة {ing.englishLang ? "الإنجليزية" : "العربية"} */}
                           {language == "ar" ? " سياسة رقم " : "Policy Number"} ({index + 1})
                          </label>
                        </div>

                        <input
                          type="text"
                          value={policy.value}
                          onChange={(e) => {
                            // ing.englishLang
                            // ?
                            setPolicies((prev) =>
                              prev.map((item) =>
                                item.id == policy.id
                                  ? { ...item, value: e.target.value }
                                  : item
                              )
                            );
                            // : setArIngs((prev) =>
                            //     prev.map((item) =>
                            //       item.id == ing.id
                            //         ? {
                            //             ...item,
                            //             ar_value: e.target.value,
                            //           }
                            //         : item
                            //     )
                            //   );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          <hr />

          {/* <div className='d-flex align-items-center gap-4'>
              <label htmlFor='hasOptions'>هل المنتج يحتوي على إضافات؟</label>

              <div class='toggle-switch'>
                <input
                  checked={hasOptions}
                  class='toggle-input'
                  id='hasOptions'
                  type='checkbox'
                  onChange={(e) => {
                    setHasOptions(e.target.checked);
                  }}
                />
                <label class='toggle-label' for='hasOptions'></label>
              </div>
            </div>

            {hasOptions ? (
              <div className='ingrediants mt-4'>
                <div className='d-flex align_items-center justify-content-between'>
                  <h4>المكونات</h4>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setArIngs([
                        ...arIngs,
                        {
                          ar_value: "",
                          en_value: "",
                          englishLang: false,
                          id: arIngs.length + 1,
                        },
                      ]);
                      console.log(arIngs);
                    }}
                  >
                    {plus}
                  </div>
                </div>

                {arIngs && arIngs.length >= 1
                  ? arIngs.map((ing, index) => {
                      return (
                        <div className='ing_container mb-3'>
                          <div
                            className='deleteIng'
                            onClick={() => {
                              setArIngs((prev) =>
                                prev.filter((item) => item.id != ing.id)
                              );
                            }}
                          >
                            {exitModal}
                          </div>

                          <div className='fields row w-100 '>
                            <div className='field_input col-sm-12'>
                              <div className='d-flex align-items-center gap-4'>
                                <label htmlFor=''>
                                  باللغة{" "}
                                  {ing.englishLang ? "الإنجليزية" : "العربية"}
                                </label>

                                <div class='toggle-switch'>
                                  <input
                                    class='toggle-input'
                                    id={`prod_desc_${index}`}
                                    type='checkbox'
                                    onChange={(e) => {
                                      setArIngs((prev) =>
                                        prev.map((item) =>
                                          item.id == ing.id
                                            ? {
                                                ...item,
                                                englishLang: e.target.checked,
                                              }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  <label
                                    class='toggle-label'
                                    for={`prod_desc_${index}`}
                                  ></label>
                                </div>
                              </div>

                              <input
                                type='text'
                                value={
                                  ing.englishLang ? ing.en_value : ing.ar_value
                                }
                                onChange={(e) => {
                                  ing.englishLang
                                    ? setArIngs((prev) =>
                                        prev.map((item) =>
                                          item.id == ing.id
                                            ? {...item, en_value: e.target.value}
                                            : item
                                        )
                                      )
                                    : setArIngs((prev) =>
                                        prev.map((item) =>
                                          item.id == ing.id
                                            ? {
                                                ...item,
                                                ar_value: e.target.value,
                                              }
                                            : item
                                        )
                                      );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}

                <div className='d-flex align_items-center justify-content-between'>
                  <h4>خطوات التحضير</h4>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSteps([
                        ...steps,
                        {
                          ar_value: "",
                          en_value: "",
                          englishLang: false,
                          id: steps.length + 1,
                        },
                      ]);
                    }}
                  >
                    {plus}
                  </div>
                </div>

                {steps && steps.length >= 1
                  ? steps.map((step, index) => {
                      return (
                        <div className='ing_container mb-3'>
                          <div
                            className='deleteIng'
                            onClick={() => {
                              setSteps((prev) =>
                                prev.filter((item) => item.id != step.id)
                              );
                            }}
                          >
                            {exitModal}
                          </div>
                          <div className='fields row w-100 '>
                            <div className='field_input col-sm-12'>
                              <div className='d-flex align-items-center gap-4'>
                                <label htmlFor=''>
                                  الخطوة ({index + 1}) باللغة{" "}
                                  {step.englishLang ? "الإنجليزية" : "العربية"}
                                </label>

                                <div class='toggle-switch'>
                                  <input
                                    class='toggle-input'
                                    id={`step_${index}`}
                                    type='checkbox'
                                    onChange={(e) => {
                                      setSteps((prev) =>
                                        prev.map((item) =>
                                          item.id == step.id
                                            ? {
                                                ...item,
                                                englishLang: e.target.checked,
                                              }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  <label
                                    class='toggle-label'
                                    for={`step_${index}`}
                                  ></label>
                                </div>
                              </div>

                              <input
                                type='text'
                                value={
                                  step.englishLang ? step.en_value : step.ar_value
                                }
                                onChange={(e) => {
                                  step.englishLang
                                    ? setSteps((prev) =>
                                        prev.map((item) =>
                                          item.id == step.id
                                            ? {...item, en_value: e.target.value}
                                            : item
                                        )
                                      )
                                    : setSteps((prev) =>
                                        prev.map((item) =>
                                          item.id == step.id
                                            ? {...item, ar_value: e.target.value}
                                            : item
                                        )
                                      );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}

                <div className='d-flex align_items-center justify-content-between'>
                  <h4> المنتجات</h4>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setProducts([
                        ...products,
                        {
                          product_id: "",
                          price: "",
                          count: "",
                          id: products.length + 1,
                        },
                      ]);
                    }}
                  >
                    {plus}
                  </div>
                </div>

                {products && products.length >= 1
                  ? products.map((prod, index) => {
                      return (
                        <div className='prod_container'>
                          <div
                            className='deleteIng'
                            onClick={() => {
                              setProducts((prev) =>
                                prev.filter((item) => item.id != prod.id)
                              );
                            }}
                          >
                            {exitModal}
                          </div>

                          <div className='field_input'>
                            <label htmlFor=''>المنتج</label>
                            <SelectWithSearch
                              options={allProducts}
                              getClientData={setProdcutData}
                              defaultValue={prod}
                              onChange={(value) => {
                                console.log(prod);
                                console.log(value.id);

                                setProducts((prev) =>
                                  prev.map((item) =>
                                    item.product_id == value.id
                                      ? {...item, product_id: value.id}
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>

                          <div className='field_input'>
                            <label htmlFor=''>السعر</label>
                            <input
                              value={prod.price}
                              type='number'
                              onChange={(e) => {
                                setProducts((prev) =>
                                  prev.map((item) =>
                                    item.id == prod.id
                                      ? {...item, price: e.target.value}
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>

                          <div className='field_input'>
                            <label htmlFor=''>الوزن/الكمية</label>

                            <input
                              value={prod.count}
                              type='number'
                              onChange={(e) => {
                                setProducts((prev) =>
                                  prev.map((item) =>
                                    item.id == prod.id
                                      ? {...item, count: e.target.value}
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null} */}

          <button className="popup_agree_btn">
            {addLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
          </button>
        </form>
      )}
    </div>
  );
};

export default EditProductPage;
