import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { Archived, links } from "../../data";
import UseGeneral from "../../customHooks/useGeneral";

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { userData } = UseGeneral();
  const { language } = UseGeneral();
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };
  return (
    <aside className={`sidebar ${isOpen ? "" : "closed"}`}>
      <div className="logoCon">
        <img
          src={
            "https://res.cloudinary.com/duovxefh6/image/upload/v1710981073/24e1cbe7d0f72e8cee116ee85720cd86_ranfgv.png"
          }
          className="logo"
          alt=""
        />
        <span
          onClick={() => toggleNav()}
          className={`menu ${isOpen ? "open" : "close"}`}
        >
          {
            <span>
              {" "}
              <i className="fas fa-bars"></i>
            </span>
          }
        </span>
      </div>
      <ul>
        {links(userData?.data?.email).map((link, index) =>
          link?.to ? (
            <li key={index}>
              <NavLink to={link.to}>
                <i className={link.icon}></i>
                <span>
                  {" "}
                  {language == "ar" ? link.labelArabic : link?.labelEnglish}
                </span>
              </NavLink>
            </li>
          ) : null
        )}
        {Archived && Archived?.length ? (
          <h5>{language == "ar" ? "الأرشيف" : "Archive"}</h5>
        ) : null}
        {Archived.map((link, index) =>
          link?.to ? (
            <li key={index}>
              <NavLink to={link.to}>
                <i className={link.icon}></i>
                <span>
                  {" "}
                  {language == "ar" ? link.labelArabic : link?.labelEnglish}
                </span>
              </NavLink>
            </li>
          ) : null
        )}
      </ul>
    </aside>
  );
};

export default SideNav;
